import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError,  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '#environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryCoordinationCRUDService {

  constructor(private _http: HttpClient) { }

  getConvoy():any   {
    return this._http.get(`${environment.endpoints.deliveryCoordination}/convoy`);
  }

  deleteConvoy(id) {
    return this._http
      .delete(`${environment.endpoints.deliveryCoordination}/convoy/${id}`)
      .pipe(catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: err.error.message,
        });
      }));
  }

  createConvoy(data): Observable<any> {

    return this._http.post(`${environment.endpoints.deliveryCoordination}/convoy`, data)
        .pipe(catchError(err => {
            return observableThrowError(err.error);
        }));
  }

  updateConvoy(id, data): Observable<any> {
    return this._http
        .put(`${environment.endpoints.deliveryCoordination}/convoy/${id}`, data)
        .pipe(catchError(err => {
            return observableThrowError(err.error);
        }));
  }

  getRoadCarrier(): any {
    return this._http.get(`${environment.endpoints.deliveryCoordination}/roadcarrier`);
  }

  getVehiclesTypes(): any {
    return this._http.get(`${environment.endpoints.deliveryCoordination}/delivery/find-vehicles-types`);
  }

  updateRoadCarrier(id, data): Observable<any> {
    return this._http.put(`${environment.endpoints.deliveryCoordination}/roadcarrier/${id}`, data);
  }
}
