export const SPECIAL_TAX_REGIME_DROPDOWN = [
    { id: 'NÃO HÁ', itemName: 'NÃO HÁ' },
    { id: 'REIDI', itemName: 'REIDI' },
    { id: 'REPORTO', itemName: 'REPORTO' },
    { id: 'ROTA 2030', itemName: 'ROTA 2030' },
    { id: 'PIS/COFINS PRESUMIDO', itemName: 'PIS/COFINS PRESUMIDO (MEDICAMENTOS)' },
    { id: 'SUFRAMA', itemName: 'SUFRAMA (ZONA FRANCA DE MANAUS)' },
    { id: 'REGIME ESPECIAL ICMS-ST', itemName: 'REGIME ESPECIAL ICMS-ST' },
    { id: 'RECAP', itemName: 'RECAP' },
    { id: 'PROGRAMA MOVER', itemName: 'PROGRAMA MOVER (AUTOMOTIVO)' },
    { id: 'DRAWBACK', itemName: 'DRAWBACK (IMPORTAR PARA EXPORTAR)' },
    { id: 'OUTROS', itemName: 'OUTROS - ESPECIFICAR' }
]
