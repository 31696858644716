import { environment } from '#environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FreightForwarderService {
  constructor(private http: HttpClient) {}

  //Test endpoint with delay return use:
  //https://deelay.me/5000/ + url

  getFreightForwarders(system = null): Observable<any> {
    let url = `${environment.endpoints.quotationService}/freightforwarder`;

    if (system) {
      url += `/?system=${system}`;
    }

    return this.http.get(url).pipe(catchError(err => {
        return throwError(err.error);
    }));
  }

  getFreightForwarderById(idFreightForwarder): Observable<any> {
    return this.http
        .get(`${environment.endpoints.quotationService}/freightforwarder/${idFreightForwarder}`)
        .pipe(catchError(err => {
            return throwError(err.error);
        }));
  }

  createFreightForwarder(data): Observable<any> {

    return this.http.post(`${environment.endpoints.quotationService}/freightforwarder`, data)
        .pipe(catchError(err => {
            return throwError(err.error);
        }));
  }

  updateFreightForwarder(data, idFreightForwarder): Observable<any> {
    return this.http
        .put(`${environment.endpoints.quotationService}/freightforwarder/${idFreightForwarder}`, data)
        .pipe(catchError(err => {
            return throwError(err.error);
        }));
  }

  deleteFreightForwarder(data, idFreightForwarder): Observable<any> {
    return this.http
        .delete(`${environment.endpoints.quotationService}/freightforwarder/${idFreightForwarder}`, data)
        .pipe(catchError(err => {
            return throwError(err.error);
        }));
  }

  exportImage(relativePath: string): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.endpoints.quotationService}/storage/downloadBase64?imageURL=${relativePath}`, {
      observe: 'response',
      responseType: 'text',
    });
  }

  handlePrepareImageToBase64(file: string) {
    const fileImage = file;
    const fileExtension = fileImage.split('.').pop();
    return `data:image/${fileExtension};base64,`;
  }

  sendImageToStorage(file: FormData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    return this.http.post(`${environment.endpoints.quotationService}/storage`, file, { headers, responseType: 'text' });
  }
}
