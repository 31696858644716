import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const DELIVERY_COORDINATION_INDIRECT_DETAILS_TRANSPORT_RESPONSABLES = [
    {
        name: 'Armazém Geral',
        code: 'GENERAL_WAREHOUSE'
    }, {
        name: 'Cliente',
        code: 'CLIENT'
    }, {
        name: 'Comexport',
        code: 'COMEXPORT'
    }, {
        name: 'Recinto',
        code: 'ENCLOSURE'
    }, {
        name: 'Transportadora',
        code:'ROAD_CARRIER'
    }
];

export const DELIVERY_COORDINATION_INDIRECT_DETAILS_SCHEDULING_COLUMNS = [
    {
        field: 'container',
        name: 'Container',
        width: 150,
    },
    {
        field: 'flowNumber',
        name: 'Fluxo',
        width: 150,
    },
    {
        field: 'driver',
        name: 'Motorista',
        width: 200,
    },
    {
        field: 'principalPlate',
        name: 'Placa Veículo',
        width: 150,
    },
    {
        field: 'secondaryPlate',
        name: 'Placa Secundária',
        width: 150,
    },
    {
        field: 'date',
        name: 'Data',
        formatterType: 'date',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 100,
    },
    {
        field: 'time',
        name: 'Hora',
        filterType: 'hours',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 100,
    },
    {
        field: 'status',
        name: 'Status',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        formatterType: 'textStatus',
        formatterData: {
            theme: 'default',
            size: 'md',
        },
        width: 150,
    },
    {
        field: 'delete',
        name: "<span class='material-icons' style='font-size:18px'>delete_forever</span>",
        width: 80,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        formatterType: 'round',
        formatterData: { roundType: 'delete', needValue: true },
    },
];

export const DELIVERY_COORDINATIONS_INDIRECT_DETAILS_DOCUMENTS_COLUMNS: IGridColumn[] = [
    {
        field: 'gedDocumentType',
        name: 'Tipo',
        width: 350,
    },
    {
        field: 'aliasDocumentName',
        name: 'Nome',
        width: 400,
    },
    {
        field: 'documentName',
        name: '<span class="material-icons documents">download</span>',
        formatterType: 'round',
        formatterData: { roundType: 'download' },
        width: 50,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'view',
        name: '<span class="material-icons documents">visibility</span>',
        formatterType: 'round',
        formatterData: { roundType: 'view' },
        width: 50,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
];

export const DELIVERY_COORDINATIONS_INDIRECT_DETAILS_PACKAGINGS_COLUMNS: IGridColumn[] = [
    {
        field: 'type',
        name: 'Embalagens',
        width: 200,
    },
    {
        field: 'quantity',
        name: 'Volume',
        width: 200,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
];
