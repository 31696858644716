import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ExchangeAccountsService {

    constructor(private _http: HttpClient) { }

    getSubsidiaryNames(): Observable<any> {
        return this._http.get(`${environment.endpoints.importationService}/imps/subsidiaryNames`);
    }

    getAccountList(subsidiariesCode): Observable<any> {
        return this._http.get(`${environment.endpoints.exchangeService
        }/checking-account?subsidiariesCode=${subsidiariesCode}`);
    }

    getBankList(): Observable<any> {
        return this._http.get(`${environment.endpoints.domainService}/banks`);
    }

    createAccount(request): Observable<any> {
        return this._http.post(`${environment.endpoints.exchangeService}/checking-account/`, request);
    }

    editAccount(accountId, request): Observable<any> {
        return this._http.put(`${environment.endpoints.exchangeService}/checking-account/${accountId}`, request);
    }

    updatedInactiveAccount(accountId): Observable<any> {
        return this._http.put(`${environment.endpoints.exchangeService}/checking-account/update-status/${accountId}`, null);
    }

}
