import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from 'app/models/_page/Page';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { InvoiceExternal } from '../constants/invoice-external';

@Injectable()
export class InvoiceExternalService {
    currentParams = {
        imp: '',
        chassi: '',
        startDate: moment().format('01/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
        clients: [],
        crs: [],
        models: [],
        colors: [],
        manufacturerYears: [],
        modelYears: [],
        context: 'imp',
    };

    apiURI = environment.endpoints.invoiceExternalService;

    constructor(private http: HttpClient) {}

    getInvoices(request): Observable<Page<InvoiceExternal>> {
        return this.http
            .get<Page<InvoiceExternal>>(this.apiURI + '/api/invoice-external', {
                observe: 'body',
                params: request.params,
                headers: request.headers,
            })
            .pipe(
                map((invoices) => {
                    if (invoices) {
                        for (const inv of invoices.content) {
                            this.updateTotalItems(inv);
                        }
                    }
                    return invoices;
                })
            );
    }

    getInvoice(invoiceId): Observable<InvoiceExternal> {
        return this.http
            .get<InvoiceExternal>(this.apiURI + `/api/invoice-external/${invoiceId}`, {
                observe: 'body',
            })
            .pipe(
                map((invoice) => {
                    if (invoice) {
                        this.updateTotalItems(invoice);
                    }
                    return invoice;
                })
            );
    }

    getInvoiceItems(invoiceId, itemCode) {
        return this.http.get(
            this.apiURI + `/api/invoice-external/${invoiceId}/code-items/${itemCode}`
        );
    }

    getPDF(code): Observable<any> {
        return this.http
            .get(`${environment.endpoints.invoiceExternalService}/api/invoice-external/${code}/file`, { responseType: 'blob' })
            .pipe(
                map((res) => {
                    return new Blob([res], { type: 'application/pdf' });
                })
            );
    }

    update(invoice: InvoiceExternal): Observable<any> {

        return this.http.put(this.apiURI + `/api/invoice-external/${invoice.id}`, invoice, {
            observe: 'response',
            responseType: 'json',
        });
    }

    updateInvoiceItems(invoiceId, params): Observable<any> {
        return this.http.put(this.apiURI + `/api/invoice-external/${invoiceId}/code-items`, params, {
            observe: 'response',
            responseType: 'json',
        });
    }

    private updateTotalItems(invoice: InvoiceExternal) {
        if (invoice.items && invoice.items.length > 0) {
            let totalItems = 0;
            for (const item of invoice.items) {
                if (item.quantity) {
                    totalItems = totalItems + item.quantity;
                }
            }
            invoice.totalItems = totalItems;
        }
    }

    updateInvoice(invoiceId, params): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/merge-patch+json',
        });
        return this.http.patch(`${environment.endpoints.invoiceExternalService}/api/invoice-external/${invoiceId}`, params, {
            headers: headers,
        });
    }
}
