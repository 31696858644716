export const DROPDOWN_FIELDS = [
    // Premise
    'clientContractStatus', 'recurrenceOperation', 'costCenter', 'segment', 'purchaseCurrency', 'state', 'profitCenter', 'trader', 'director', 'operationalResponsible',
    // Tributary
    'mainDestinationMerchandise', 'typeTaxOperation', 'customerTaxIncentives', 'taxStudyId',
    // Jurídico & Compliance
    'responsibleLegalAnalysis',
    // Potential Solution
    'importationModality', 'comexportBranch', 'comexportSpecialRegime', 'incoterm', 'countryOfRegistration',
    // Financial
    'currency', 'nameEscrow', 'typePayment', 'fobCurrency', 'paymentForm', 'exchangePaymentTermCounting', 'interestIndexFinancialCostExchange', 'responsibleNationalizationPayment',
    'responsiblePaymentNationalizationExpenses', 'spreadInterestPeriodicity', 'comexportFeeType', 'feeCalculationBase',
    'typeDiscountApplied', 'calculationBasisApplyingDiscount', 'percentageIcmsRate', 'fixedFeeBillingBase', 'financialAnalysisResponsible',
    'importationModality', 'comexportBranch', 'comexportSpecialRegime', 'percentageIcmsRateConditionsDiscount', 'currencyFee', 'currencyExchange',
    // Despesas Operacionais
    'internationalShipping', 'domesticShipping', 'internationalSecurePayment',
    'forwardingAgent', 'billingBaseDispatcherValue', 'currencyDispatcher', 'currencyExTariff',
    // Manual do Cliente
    'relationshipExporterPurchaser', 'systemExporterIntegrationType', 'systemClientIntegrationType', 'greenlight', 'productCode', 'productDescription',
    'measureUnit', 'licensingBody', 'mainBoardingModal', 'mainProductPackaging', 'mainIncoterm', 'clearanceNegotiatedTable',
    'generalStorageNegotiatedTable', 'billingBase', 'valueMultiplier', 'maximumValueMultiplier', 'responsibleForUnloadingTheVehicleAtTheCustomer'

]
export const DATE_FIELDS = [
    // Premise
    'elaborationDate', 'effectiveDate',
    // Legal
    'partnersDossierDate', 'companyDossierDate',
    // Manual do Cliente
    'pceHandlingAuthorizationExpirationDate', 'pfControlledProductLicenseExpirationDate', 'pcControlledProductLicenseExpirationDate',
    // Financeiro
    'exchangePaymentTermDate'
]

export const BOOLEAN_FIELDS = [
    // Premise
    'nonIcmsContributingClient', 'leasingOperation',
    // Potential Solution
    'hasUnlimitedRadar', 'linkedToComexportRadar', 'productWithoutNationalSimilar', 'monoPisCofins',
    'accumulatesPisCofins', 'exTariff', 'icmsSubstitution', 'productIsCopperOrSteel',
    'productDestinedAnotherState',
    // Legal
    'companyCadastralSituation', 'partnersCadastralSituation', 'hasNda',
    // Financial
    'approvedCashFlow', 'approvedExposureFlow', 'preAnalysisPerformed', 'waiver', 'comfortLetterMatriz',
    'corporateGuarantee', 'partnersGuarantee', 'promissoryNote', 'guarantee', 'bankGuarantee', 'customerBalance',
    'standbyLetterOfCredit', 'hedgeBeforeBilling', 'chargeIOF', 'applyTaxIncentiveTransfer',
    // Despesas Operacionais
    'comexportFreightDelivery', 'adValoremDeliveryShipping',
    // Manual do Cliente
    'clientHasAfeAnvisaAuthorization', 'subsidiaryHasAfeAnvisaAuthorization', 'systemExporterIntegration', 'systemClientIntegration',
    'internationalEndorsedClientComexportPolicy', 'nationalEndorsedClientComexportPolicy', 'conveyorHasAfeAnvisaAuthorization',
    'warehouseHasAfeAnvisaAuthorization', 'generalStorageHasAfeAnvisaAuthorization', 'qualifiedConveyor', 'qualifiedWarehouse', 'qualifiedGeneralStorage',
    'approvedComexportConveyor', 'approvedComexportWarehouse', 'approvedComexportGeneralStorage', 'aditionalDataOrderNumber',
    'xmlInvoiceOrderNumber', 'originCertificate', 'newProduct', 'privateBrand', 'royaltyRights',
    'repackagingRequired', 'antidumping', 'reworkRequired', 'industrializationRequired', 'uniqueInBrazil', 'hasExTariff',
    'hasTemperatureControl', 'hasInventoryControl', 'hasChassiSerie', 'hasGtinCode', 'hasLcvmCode', 'hasAnpRegister',
    'liRequired', 'inmetroRequired', 'preShipmentTechnicalInspectionRequired', 'dangerousProduct', 'hasEmergencyToken', 'pceProduct',
    'federalPoliceControlledProduct', 'civilPoliceControlledProduct', 'reverseLogisticsProduct', 'returnablePackagingProduct',
    'dtaRemoval', 'unloadContainer', 'generalStorageRemoval',
]
