export const PROFIT_CENTERS_DROPDOWN = [
    {id: "NOVOS_NEGOCIOS", itemName: "NOVOS NEGÓCIOS"},
    {id: "PETROQUIMICO", itemName: "PETROQUÍMICO"},
    {id: "QUIMICO", itemName: "QUÍMICO"},
    {id: "INFRAESTRUTURA", itemName: "INFRAESTRUTURA"},
    {id: "ACO", itemName: "AÇO"},
    {id: "TEXTIL", itemName: "TÊXTIL"},
    {id: "IMPORTACAO_INDIRETA", itemName: "IMPORTAÇÃO INDIRETA"},
    {id: "FERTILIZANTE", itemName: "FERTILIZANTE"},
    {id: "AUTOMOTIVO", itemName: "AUTOMOTIVO"},
    {id: "AVIACAO", itemName: "AVIAÇÃO"},
    {id: "A1_GERENCIAL", itemName: "A1 GERENCIAL"},
    {id: "CXT_GERENCIAL", itemName: "CXT GERENCIAL"},
    {id: "RPP", itemName: "RPP"},
    {id: "EXPORTACAO", itemName: "EXPORTAÇÃO"},
    {id: "JVCC", itemName: "JVCC"},
    {id: "APOIO_FINANCEIRO", itemName: "APOIO FINANCEIRO"},
    {id: "RATEIO", itemName: "RATEIO"},
    {id: "SOCIOS", itemName: "SÓCIOS"},
    {id: "MAQUINAS_E_EQUIPAMENTOS", itemName: "MÁQUINAS E EQUIPAMENTOS"},
    {id: "SOLAR", itemName: "SOLAR"},
    {id: "NOVELIS", itemName: "NOVELIS"},
    {id: "INDEFINIDO", itemName: "INDEFINIDO"}
];
